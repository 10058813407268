import React from "react"

import SEO from "../components/seo"
import ButtonLink from "../components/buttonLink"

const Sent = () => (
  <div
    className="sentPage"
    style={{ justifySelf: "center", paddingBottom: "20px" }}
  >
    <SEO title="Sent" />
    <h1 style={{ textAlign: "center", marginBottom: 0 }}>Success!</h1>
    <p style={{ textAlign: "center", margin: 0 }}>Your message was sent</p>
    <ButtonLink buttonText="Send another" linkTo="/contact"></ButtonLink>
    {/* <ButtonLink buttonText="Home" linkTo="/"></ButtonLink> */}
  </div>
)

export default Sent
